.not-found-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f8f9fa;
  }
  
  .not-found-content {
    text-align: center;
  }
  
  .heading {
    font-size: 6rem;
    color: #dc3545;
  }
  
  .paragrapgh {
    font-size: 1.5rem;
    margin-bottom: 2rem;
    color: #6c757d; 
  }
  
  .icon {
    font-size: 3rem;
    color: #ffc107;
  }
  
  .attractive-button {
    background-color: #3498db;
    color: #fff;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
  }
  
  .attractive-button:hover {
    background-color: #2980b9;
  }
  