  
  .flowcontainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    font-family: "Lucida Console", "Courier New", monospace;
    margin: 0;
    padding: 0;
    background-color: #242424;
    color: #fff;

    line-height: 1.5;
    color-scheme: light dark;
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    margin: 0 auto;
    padding: 1rem;
    text-align: center;
  }
  
  
  .flow-panel{
    width: '50vw';
    height: '95vh';
  }
  
  
  .buttons-panel {
    display: flex;
    flex-direction: column;
    width: 40%;
    align-items: center;
    color: antiquewhite;
    background-color: #333;
    div{
      padding: 20px;
      margin: 10px;
    }
  }
  
  .text-panel {
    display: flex;
    width: 55%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 10px;
    input{
      width: 95%;
    }
    textarea{
      width: 95%;
      min-height: 80px;
      resize: vertical;
      max-height: 300px;
    }
  }
  
  .flowinput, .flowtextarea{
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  
  .flowbutton {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    
  }
  
  .flowbutton:hover {
    opacity: 0.8;
  }

  .download-btn {
    border: 1px solid white;
    background-color:transparent;
    color: white;
    border-radius:5px;
    }
  
  .flowselect {
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
  }
  
  .addNode {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    flex-direction: row;
    justify-content: space-between;
    align-items: space-around;
  }
  
  
  @media (max-width: 768px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
    .buttons-panel{
      margin-top: 20px;
      width: 80%;
    }
  }
  
  .node {
    background-color: #4CAF50;
    color: white;
    border-radius: 14px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
  .node textarea {
    resize: none;
    width: 80%;
    height: 90%;
    text-align: center;
    margin: 5px;
    font-family: "Lucida Console", "Courier New", monospace;
    font-size: 16px;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    border: none;
    box-sizing: border-box;
    overflow: hidden;
  }
  