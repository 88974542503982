.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 94vh;
  width: 100%;
  background-color: #f8f4f4;
  text-align: center;
}

.login-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
}

.Signup-card {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 50%;
}

.login-card h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 0.5rem;
  color: #32B749;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Signup-card h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 0.5rem;
  color: #32B749;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.Signup-card .p-inputgroup{
  margin-bottom: 10px;
}

.login-card p {
  color: #666;
  font-size: 14px;
  margin-bottom: 2rem;
}

.link {
  color: #0077ff;
  cursor: pointer;
  text-decoration: underline;
}

.field {
  margin-bottom: 1.5rem;
}

.field label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
}

.field input {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.options {
  width: 100%;
  display: flex;
  justify-content:end;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 2rem;
}

.options label {
  margin-left: 0.5rem;
}

.forgot-link {
  color: #0077ff;
  cursor: pointer;
}

.pi-user {
  margin-right: 0.5rem;
}

.loginButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.SingUpButton {
  width: 100%;
  background-color: #32B749;
}

.login-form {
  max-width: 400px;
  margin: auto;
  text-align: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: white;
}

.login-form h2,
.login-form p {
  margin-bottom: 20px;
}

.divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #ccc;
}

.or {
  width: 50px;
  text-align: center;
  margin: 0 10px;
  color: #888;
}


.icon {
  margin-right: 10px;
  width: 24px;
  height: 24px;
}



.divider {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.line {
  flex: 1;
  height: 1px;
  background-color: #ccc;
}

.or {
  width: 50px;
  text-align: center;
  margin: 0 10px;
  color: #888;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 97vh;
}

.sidebar {
  flex: 0 0 250px;
  background: #f4f4f4;
}

.content {
  flex: 1;
  padding: 10px;
  background: white;
}

/* Additional styling for DataTable, Sidebar, etc. */


.NavBar{
  background: var(--surface-card);
  padding: 1.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;
}










/* Responsive adjustments */
@media (max-width: 540px) {
  .login-card {
    width: 100%;
    /* Adjust width for small devices */
    padding: 1rem;
    /* Smaller padding */
  }
  .Signup-card {
    width: 100%;
    /* Adjust width for small devices */
    padding: 1rem;
    /* Smaller padding */
  }

  .login-container {
    padding: 10px;
    /* Add some padding around the container */
  }

  .SingUpButton {
    width: 100%;
    padding: 0.5rem;
  }

  .SignupFeild {
    display: flex;
    flex-direction: column;
  }
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.brand-text {
  /* font-family: 'Poppins', sans-serif; */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px; /* Adjust font size to match logo */
  color: #1956b8; /* Match the blue color from the logo */
  font-weight: 600; /* Boldness similar to the uploaded logo */
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
  letter-spacing: 0.5px; /* Adjust letter spacing for logo text alignment */
}

.brand-text::before {
  content: "";
  position: absolute;
  top: 50%; /* Align yellow square with text */
  transform: translateY(-50%);
  left: -10px; /* Position yellow square next to the text */
  width: 8px; /* Adjust size for yellow square */
  height: 8px;
  background-color: #f7c000; /* Match the yellow color from the logo */
  border-radius: 2px; /* Slightly rounded edges for the square */
}